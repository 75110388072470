import React from "react"

import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/Layout"
import Head from "../components/Head"

const NotFoundPage = (props) => (
  <Layout>
    <Head title="404: Not found" />
    <Img fluid={props.data.bricks.childImageSharp.fluid} alt="404 not found message" />
    <p></p>
    <h1 className="heading text-lg-center">404 - NOT FOUND</h1>
    <p></p>
    <p className="text-center">So sorry but you asked for something we do not have :(</p>
  </Layout>
)

export default NotFoundPage

export const pageQuery = graphql`
  query {
    bricks: file(relativePath: {eq: "404-bricks.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1110) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
